import axios from 'axios'


// http://kegonapi.test/${lang}/api/${url}

// https://kegon-api.vermis.hu/${lang}/api/${url}

axios.defaults.withCredentials = true;
const GetData = async (url, lang) => {
  try {
    const response = await axios.get(`https://keponplapi.vermis.hu/${lang}/api/${url}`);
    return response;
  } catch (error) {
    if (error.response.status === 404) {
      window.location.href = '/error/404';
    }
    throw error;
  }
};

export default GetData;

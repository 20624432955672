import {
  ChevronDown,
  ChevronUp,
  Check,
  ExclamationCircle,
} from "react-bootstrap-icons";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useContext, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import axios from "axios";


const Calendarboxcontent = ({ moduleData }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [contactData, setContactData] = useState("");
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
  const [companyNameIsInvalid, setCompanyNameIsInvalid] = useState(false);
  const [positionIsInvalid, setPositionIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(false);
  const [messageIsInvalid, setMessageIsInvalid] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [actualData, setActualData] = useState({});
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setIsAccepted(event.target.checked);
  };

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const isValid = () => {
    if (firstName.length < 2) {
      setFirstNameIsInvalid(true);
    } else {
      setFirstNameIsInvalid(false);
    }
    if (lastName.length < 2) {
      setLastNameIsInvalid(true);
    } else {
      setLastNameIsInvalid(false);
    }
    if (companyName.length < 2) {
      setCompanyNameIsInvalid(true);
    } else {
      setCompanyNameIsInvalid(false);
    }
    if (position.length < 2) {
      setPositionIsInvalid(true);
    } else {
        setPositionIsInvalid(false);
    }

    if (!emailRegex.test(email)) {
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
    if (message.length < 2 || message.length > 100) {
      setMessageIsInvalid(true);
    } else {
      setMessageIsInvalid(false);
    }
    if (
      firstName.length > 1 &&
      lastName.length > 1 &&
      emailRegex.test(email) &&
      message.length < 101
    ) {
      return true;
    }
    return false;
  };
  const actualDataId = actualData.id;

  const handleSubmit = () => {
    axios.defaults.withCredentials = true;
    if (isValid()) {
      /*window.gtag("event", "conversion", {
        send_to: "AW-11139665393/4P_0CJnPpZYYEPGb578p"
      });*/
      axios
        .get(`https://keponplapi.vermis.hu/sanctum/csrf-cookie`, {})
        .then(() =>
          axios.post("https://keponplapi.vermis.hu/api/booking", {
            id: actualDataId,
            lastname: lastName,
            firstname: firstName,
            companyName: companyName,
            position: position,
            email: email,
            phone: phone,
            message: message,
            privacy: isAccepted,
            terms: isAccepted,
          })
        )
        .then((res) => {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setCompanyName("");
          setPosition("");
          setFirstNameIsInvalid(false);
          setLastNameIsInvalid(false);
          setEmailIsInvalid(false);
          setPositionIsInvalid(false);
          setMessageIsInvalid(false);
          setIsAccepted(false);
          setCompanyNameIsInvalid(false);
          setPositionIsInvalid(false);

          setSubmitSuccessful(true);
          setSubmitFailed(false);
          setTimeout(() => {
            setSubmitSuccessful(false);
          }, 3000);
        })
        .catch((error) => {
          setError(error.response.status);
          setSubmitFailed(true);
        });
    } else {
      setSubmitFailed(true);
    }
  };

  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [show, setShow] = useState(false);

  const { lang } = useContext(LanguageContext);
  const handleClose = () => setShow(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const handleShow = (data) => {
    window.gtag("event", "conversion", {
      send_to: "AW-11139665393/QZVTCOefopYYEPGb578p"
    });
    window.open(
        data.booking_url,
        '_blank' // <- This is what makes it open in a new window.
    );
    //setShow(true);
    //setActualData(data);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const countdownDate = new Date(actualData.start).getTime();
      const timeDifference = countdownDate - now;

      setTimeLeft({
        days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeDifference % (1000 * 60)) / 1000),
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [actualData.start]);

  const handleClick = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  const renderedItems = moduleData.map((item, index) => {
    const icon = (
      <span className="text-2xl">
        {expandedIndex !== index ? (
          <ChevronUp style={{ fontSize: "1.5rem" }} />
        ) : (
          <ChevronDown style={{ fontSize: "1.5rem" }} />
        )}
      </span>
    );

    return (
      <Container className="" key={index}>
        <div className="calendar-boxx  ">
          <div className="modul-container d-flex flex-column align-items-center ">
            {expandedIndex !== index ? (
              <div
                className="module-box-main d-flex justify-content-between align-items-center col-12 p-3 ps-5 pe-5 border-bottom border-warning"
                onClick={() => handleClick(index)}
              >
                {item.date}
                {icon}
              </div>
            ) : (
              <div
                className="module-box d-flex justify-content-between align-items-center col-12 p-3 ps-5 pe-5 border-bottom border-warning"
                onClick={() => handleClick(index)}
              >
                {item.date}
                {icon}
              </div>
            )}

            {expandedIndex !== index &&
              item.trainings &&
              item.trainings.map((data) => (
                <div
                  key={data.id}
                  className="module-box-open col-12 p-2 ps-3 pe-3 "
                >
                  <div className="calendarbox-open d-flex justify-content-center flex-wrap col-12  mt-5 mb-3  border-bottom">
                    <div className="calendar-img d-flex align-items-center">
                      <img className="img-fluid mb-5" src={data.image} alt="" />{" "}
                    </div>

                    <div className=" mb-5 col-lg-2 text-center pe-2 ps-2">
                      <div className=" pb-5 ">
                        {lang === "en" ? "Training" : "Kurs"}
                      </div>

                      <a
                        className="text-white text-center"
                        style={{ textDecoration: "none" }}
                        href={data.link}
                      >
                        {data.name}{" "}
                      </a>
                    </div>
                    <div className=" mb-5 col-lg-2 text-center">
                      <div className=" pb-5 ">
                        {lang === "en" ? "Date" : "Data"}
                      </div>
                      {data.start.substring(0, 10)} -{" "}
                      {data.end.substring(8, 10)}
                    </div>
                    <div className=" mb-5 text-center col-lg-1 pe-2 ps-2">
                      <div className=" ">
                        {lang === "en" ? "Language" : "Język"}
                      </div>
                      <p className="text-uppercase pt-5 ">{data.language}</p>
                    </div>
                    <div className=" mb-5 text-center col-lg-2">
                      <div className=" pb-5 ">
                        {lang === "en" ? "Trainer" : "Trener"}
                      </div>

                      {data.trainers.map((trainer) => (
                        <div key={trainer.name}>{trainer.name}</div>
                      ))}
                    </div>

                    <div className="place mb-5 text-center col-lg-1 pe-2 ps-2">
                      <div className="pb-4">
                        {lang === "en" ? "Free places" : "Pozostało miejsc"}
                      </div>
                      <div className="d-flex justify-content-center align-items-center ">
                        <div className="pt-4">{data.places}</div>
                        {data.places <= 5 ? (
                          <div className="">
                            {" "}
                            <p className="mb-1 ">
                              <ExclamationCircle
                                className="d-flex align-items-center justify-content-center bg-danger rounded-circle  border-danger ms-1 mt-3"
                                style={{ fontSize: "2rem" }}
                              />
                            </p>{" "}
                          </div>
                        ) : (
                          <div className=""></div>
                        )}
                      </div>
                    </div>
                    <div
                      className=" mb-5 text-center col-lg-1"
                      onClick={() => handleShow(data)}
                    >
                      <div className="mb-4 pb-3">
                        {lang === "en" ? "Book now" : "Zarezerwuj teraz"}
                      </div>
                      {moduleData &&
                      timeLeft.days <= 0 &&
                      timeLeft.hours <= 0 &&
                      timeLeft.minutes <= 0 &&
                      timeLeft.seconds <= 0 ? (
                        <div className="d-flex justify-content-center">
                          <div className=" check-hover d-flex justify-content-center align-items-center">
                            <Check
                              className="rounded-circle  border-0  "
                              style={{ fontSize: "2rem" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className=" check-hover d-flex justify-content-center align-items-center">
                            <Check
                              className="rounded-circle  border-0  "
                              style={{ fontSize: "2rem" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <Modal
                    className="d-flex align-items-center justify-content-center text-white "
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header
                      className="d-flex justify-content-center "
                      closeButton
                      style={{ backgroundImage: "url(./contactus.png)" }}
                    >
                      <div className="d-flex justify-content-center ">
                        <Modal.Title className="text-center ps-5">
                          {actualData.name}
                        </Modal.Title>
                        <Modal.Title className="" hidden>
                          {actualData.id}
                        </Modal.Title>
                      </div>
                    </Modal.Header>
                    <Container
                      className="modal-contact-container overflow-auto"
                      style={{ backgroundImage: "url(./contactus.png)" }}
                    >
                                        {submitSuccessful ? (
                  <div className="alert alert-success">
                    {lang === "en"
                      ? "Your message has been sent!"
                      : "Az üzenetét elküldtük!"}
                  </div>
                ) : (
                  error && (
                    <div className="alert alert-danger">
                      {lang === "en"
                        ? "Something went wrong"
                        : "Valami hiba történt"}
                    </div>
                  )
                )}
                      <Container className="mt-4 ">
                        <Row>
                          <div className="col">
                            <div className="row g-3">
                              <div className="col-lg-6">
                                {lastNameIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*At least two characters"
                                      : "*Co najmniej dwie postacie"}
                                  </small>
                                )}
                                <input
                                  type="text"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3 "
                                  placeholder={
                                    lang === "en" ? "Last Name*" : "Vezetéknév*"
                                  }
                                  aria-label="Last name"
                                  value={lastName}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-lg-6">
                                {firstNameIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*At least two characters"
                                      : "*Co najmniej dwie postacie"}
                                  </small>
                                )}
                                <input
                                  type="text"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                                  placeholder={
                                    lang === "en"
                                      ? "First Name*"
                                      : "Keresztnév*"
                                  }
                                  aria-label="First name"
                                  value={firstName}
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Container>
                      <Container>
                        <Row>
                          <div className="col">
                            <div className="row g-3">
                              <div className="col-lg-6 pt-3">
                                {companyNameIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*At least two characters"
                                      : "*Co najmniej dwie postacie"}
                                  </small>
                                )}
                                <input
                                  type="text"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                                  placeholder={
                                    lang === "en" ? "Company Name*" : "Cégnév*"
                                  }
                                  aria-label="Company name"
                                  value={companyName}
                                  onChange={(e) => {
                                    setCompanyName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-lg-6 pt-3">
                                {/* {positionIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*At least two characters"
                                      : "*Co najmniej dwie postacie"}
                                  </small>
                                )} */}
                                <input
                                  type="text"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                                  placeholder={
                                    lang === "en" ? "Position" : "Pozíció"
                                  }
                                  aria-label="Position"
                                  value={position}
                                  onChange={(e) => {
                                    setPosition(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Container>
                      <Container>
                        <Row>
                          <div className="col">
                            <div className="row g-3">
                              <div className="col-lg-6 pt-3">
                                {emailIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*Wrong e-mail format"
                                      : "*Rossz e-mail formátum"}
                                  </small>
                                )}
                                <input
                                  type="email"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                                  placeholder={
                                    lang === "en" ? "E-mail*" : "E-mail*"
                                  }
                                  aria-label="E-mail*"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                              <div className="col-lg-6 pt-3">
                                {phoneIsInvalid && (
                                  <small style={{ color: "red" }}>
                                    {lang === "en"
                                      ? "*Minimum 6 maximum 20 number"
                                      : "*Minimum 6 maksymalnie 20 numerów"}
                                  </small>
                                )}
                                <input
                                  type="number"
                                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                                  placeholder={
                                    lang === "en"
                                      ? "Phone number*"
                                      : "Numer telefonu*"
                                  }
                                  aria-label="Phone number*"
                                  value={phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-2">
                            {messageIsInvalid && (
                              <small style={{ color: "red" }}>
                                {lang === "en"
                                  ? "*At least 2, maximum 100 character"
                                  : "*Co najmniej 2, maksymalnie 100 znaków"}
                              </small>
                            )}
                            <textarea
                              type="text"
                              className="form-control border-bottom rounded-0 p-0 text-white pb-3 mt-2 "
                              rows={3}
                              placeholder={lang === "en" ? "Message" : "Wiadomość"}
                              aria-label="Message"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                        </Row>
                      </Container>
                      <Container>
                        <div className="contact-us-understand  col-lg-12">
                          <div className="d-flex flex-row align-items-start">
                            <input
                              type="checkbox"
                              value="1"
                              name="group1"
                              className="contact-us-input mt-2 me-3 "
                              onChange={handleChange}
                              onClick={(e) => setIsAccepted(e.target.checked)}
                            />

                            <p className="">
                              {lang === "en"
                                ? "I understand and i accept the "
                                : "Megértem és elfogadom az "}
                              <a className="ps-1" href={lang === "en" ? "https://keponplapi.vermis.hu/uploads/files/Adatkezelési tájékoztató_230322_ENG.pdf"  : "https://keponplapi.vermis.hu/uploads/files/Adatkezelési tájékoztató_230322_HU.pdf"}>
                                {lang === "en"
                                  ? "Privacy notice"
                                  : "Adatkezelési tájékoztatót "}
                              </a>{" "}
                            </p>
                          </div>
                          <div className="d-flex flex-row align-items-start">
                            <input
                              type="checkbox"
                              value="1"
                              name="group1"
                              className="mt-2 me-3"
                              onChange={handleChange}
                              onClick={(e) => setIsAccepted(e.target.checked)}
                            />
                            <p className="">
                              {" "}
                              {lang === "en"
                                ? "I understand and i accept the "
                                : "Megértem és elfogadom az "}
                              <a className="ps-1" href={lang === "en" ? "https://keponplapi.vermis.hu/uploads/files/Adatkezelési tájékoztató_230322_ENG.pdf"  : "https://keponplapi.vermis.hu/uploads/files/Adatkezelési tájékoztató_230322_HU.pdf"}>
                                {lang === "en"
                                  ? "Terms and Conditions"
                                  : "Általános Szerződési Feltételeket "}{" "}
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </Container>

                      <Container>
                        <Row>
                          <div className="col-lg-4 pt-2 mt-5">
                            <button
                              className="button-book2 mb-5"
                              onClick={() => handleSubmit()}
                            >
                              {lang === "en" ? "SEND" : "WYŚLIJ"}
                            </button>

                          </div>
                        </Row>
                      </Container>
                    </Container>
                  </Modal>
                </div>
              ))}
          </div>
        </div>
      </Container>
    );
  });
  return <div>{renderedItems}</div>;
};

export default Calendarboxcontent;
